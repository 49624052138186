//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "productosConErrorModal",
  props: ["model", "productos", "title", "label"],
  data() {
    return {
      productosModal: false,
      columnas: [
        {
          name: "clave",
          label: "Clave",
          align: "left",
          field: "clave",
          headerClasses: "w20p",
          classes: "cell-truncate",
        },
        {
          name: "descripcion",
          label: "Descripción",
          align: "left",
          field: "descripcion",
          headerClasses: "",
          classes: "cell-truncate",
        },
        {
          name: "precioMinimo",
          label: "Precio Mínimo",
          align: "right",
          field: "precioMinimo",
          headerClasses: "w15p",
          format: (val) => this.accounting.formatMoney(val),
        },
      ],
    };
  },
  watch: {
    model(value) {
      this.productosModal = value;
    },
  },
  methods: {
    onClose() {
      this.$emit("on-close");
    },
    onShow() {},
  },
};
